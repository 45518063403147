<template>
  <v-container grid-list-s class="pa-10">
    <h2 class="text-h4 font-weight-bold primary--text">
      {{ $t('notificationsPageTitle') }}
    </h2>
    <v-divider />
    <div v-for="(notification, i) in notifications" :key="i" class="mt-4">
      <v-alert
        v-if="!notification.is_read"
        color="success accent-4"
        dismissible
        @input="readNotification(notification.pk)"
      >
        <v-row align="center">
          <v-col class="grow white--text"> {{ notification.content }} </v-col>
          <v-col class="shrink">
            <v-btn @click="handleAction(notification.tag)">{{
              notification.tag
            }}</v-btn>
          </v-col>
          <v-divider class="mx-4" vertical />
          <v-col class="white--text shrink  col-2">
            {{ notification.created_at | toLocalTZ | moment('from', 'now') }}
          </v-col>
        </v-row>
      </v-alert>
    </div>
    <v-divider />

    <v-row v-if="loadMore" justify="center" class="my-4">
      <v-btn depressed color="primary" @click="getMoreNotifications">
        {{ $t('loadMore') }}
      </v-btn>
    </v-row>
  </v-container>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'

export default Vue.extend({
  data: () => ({}),
  computed: {
    ...mapState({
      notifications: state => state.notifications.notifications,
      loadMore: state => state.notifications.loadMore
    })
  },
  created() {
    this.getNotifications()
  },
  methods: {
    ...mapActions([
      'getNotifications',
      'getMoreNotifications',
      'readNotification'
    ]),
    handleAction(tag) {
      switch (tag) {
        case 'Accounts':
          this.$router.push('/profile')
          break

        case 'Consultations':
          this.$router.push('/consultations')
          break

        case 'Payments':
          this.$router.push('/subscriptions')
          break
      }
    }
  }
})
</script>
